import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/datetimepicker'
import firebase from './plugins/firebase'
import './plugins/moment'

Vue.config.productionTip = false

let app;

firebase.auth().onAuthStateChanged((user) => {

  if (user) {
    store.state.user = user.email
  } else {
    store.state.user = null
  }

  if (!app) {
    app = new Vue({
      store,
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
});

