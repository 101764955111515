import Vue from 'vue'
import firebase from "firebase/app"
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

Vue.use(firebase)

const firebaseConfig = {
    apiKey: "AIzaSyDA3gvRI7y9l6Pxb_hDD7Bo09hzc_5869k",
    authDomain: "click-bc.firebaseapp.com",
    projectId: "click-bc",
    storageBucket: "click-bc.appspot.com",
    messagingSenderId: "312776839742",
    appId: "1:312776839742:web:54d125581b9391d948f76f"
}

export default firebase.initializeApp(firebaseConfig)

// utils - use import { db, auth, functions } from '../plugins/firebase'
export const db = firebase.firestore()
export const storage = firebase.storage()
export const auth = firebase.auth()
export const functions = firebase.functions()
export const fb = firebase
