
<template>
  <v-app id="inspire">
    <v-navigation-drawer
            v-model="drawer"
            app
    >
      <v-list>

        <v-list-item to="/">
          Home
        </v-list-item>
        <!-- v-list-item to="/register">
          Register
        </v-list-item></ -->
        <v-list-item to="/upload">
          Upload
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Click-BC Database</v-toolbar-title>

      <v-spacer />

      <v-container style="width: 300px;">
        <v-row>
          <v-col cols="7">
            <v-text-field v-if="!this.$store.state.user"
                          v-model="pass"
                          :disabled="logging"
                          placeholder="Password"
                          dense
                          hide-details
                          outlined
                          full-width
                          type="password"
            ></v-text-field>
          </v-col>
          <v-col cols="5">

            <v-btn v-if="!this.$store.state.user"
                   outlined
                   :loading="logging"
                   :disabled="logging"
                   @click="checkPassword(pass)"
                   width="100%"
                   height="40px"
            >login</v-btn>
            <v-btn v-else
                   :loading="logging"
                   :disabled="logging"
                   outlined
                   @click="firebaseLogout()"
                   width="100%"
                   height="40px"
            >logout</v-btn>

          </v-col>
        </v-row>
      </v-container>





    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

  import { functions, auth } from './plugins/firebase'

export default {
  name: 'App',

  data: () => ({
    drawer: null,
    pass: null,
    logging: false,
  }),

  methods: {
    checkPassword(password) {

      this.logging = true

      functions.httpsCallable('auth')({pass: password})
              .then(r => {

                this.pass = null

                if (r.data) {
                  this.firebaseLogin({email: r.data.user, password: r.data.pass})
                } else {
                  console.error('wrong password')
                  this.logging = false
                }
              })
              .catch((error) => {
                this.pass = null
                console.error(error.message)
                this.logging = false
              });
    },

    firebaseLogin({email, password}) {

      auth.signInWithEmailAndPassword(email, password)
              .then((userCredential) => {
                console.log('logged in', userCredential.user.email)
                this.logging = false
              })
              .catch((error) => {
                console.error(error.message)
                this.logging = false
              });

    },

    firebaseLogout() {

      this.logging = true

      auth.signOut().then(() => {
        console.log('logged out')
        this.logging = false
      }).catch((error) => {
        console.error(error.message)
        this.logging = false
      });
    }
  },

};
</script>
